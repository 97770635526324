<template>
  <div class="recaptcha">
    <div class="recaptcha__widget" id="g-recaptcha" :data-sitekey="sitekey"></div>
    <div v-if="errors && errors.length" class="recaptcha__errors">
      <span class="recaptcha__errors-item" v-for="(err, i) in errors" :key="i">{{ err }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "GrecaptchaComponent",
  props: {
    errors: Array,
  },
  data() {
    return {
      recaptchaWidget: null,
      sitekey: "6Ldn3_YqAAAAAB_UGyCz52n7VbEW6pl5_gJjpuj-",
    };
  },
  mounted() {
    // инициируем виджет капчи, но не берем сразу значение в callback, т.к. оно может сброситься из-за бездействия
    // значение будет брать из getResponse
    if (window.grecaptcha) {
      this.recaptchaWidget = window.grecaptcha.render("g-recaptcha", {
        sitekey: this.sitekey,
      });
    }
  },
  computed: {
    overlays() {
      return this.$store.state._overlays;
    },
    show() {
      return Object.values(this.overlays).some((k) => k);
    },
  },
  methods: {
    response() {
      return window.grecaptcha.getResponse(this.recaptchaWidget);
    },
  },
};
</script>

<style lang="stylus">
.recaptcha {
	display flex
	flex-direction column
	gap: 4px

	&__widget {
		display flex
		justify-content center
	}

	&__errors {
		display flex
		flex-direction column
		font-weight: 400;
		font-size: 0.75rem;
		line-height: 15px;
		color: var(--red);
		gap 3px
	}
}
</style>
